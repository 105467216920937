import { Box, Flex } from '@rebass/grid'
import { OutlineButton, theme } from '../util/style'

import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import BlogCopyRow from '../components/blog-page/PageBlog/BlogCopyRow'
import BlogMediaRowFull from '../components/blog-page/PageBlog/BlogMediaRowFull'
import BlogQuoteRow from '../components/blog-page/PageBlog/BlogQuoteRow'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
import BlogSubscribeRow from '../components/blog-page/PageBlog/BlogSubscribeRow'
import Copy from '../components/page-copy'
import Image from '../components/page-image-new'
import Image1 from '../images/etc/hh-banyo-haus-AM-01.jpg'
import Image2 from '../images/etc/hh-banyo-haus-AM-010.jpg'
import Image3 from '../images/etc/hh-banyo-haus-AM-013.jpg'
import Image4 from '../images/etc/hh-banyo-haus-AM-02.jpg'
import { Link } from 'gatsby'
import PageButtons from '../components/page-buttons'
import PageHeader from '../components/v2/PageHeader'
import PageHeroGallery from '../components/page-hero-gallery'
import PageOverview from '../components/page-overview'
import React from 'react'
import Row from '../components/page-content-row'
import SEO from '../components/seo'
import { connect } from 'react-redux'
import { setLightboxForm } from '../actions'
import styled from 'styled-components'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

const HHImage1 = 'etc/open-haus-1.png'
const HHImage2 = 'etc/open-haus-2.png'
const HHImage3 = 'etc/open-haus-3.png'

const slides = [
  {
    image: HHImage1,
  },
]

const mapDispatchToProps = (dispatch) => ({})

const Page = ({ dispatch }) => {
  const isClosed = false

  const registerForm = {
    category: 'Open Haus Pre-registration',
    title: 'Open Haus',
    redirect: '/open-haus-thank-you',
    form: 'open-haus',
  }
  const subscribeForm = {
    category: 'Open Haus Subscribe Form',
    title: 'Open Haus Subscribe Form',
    redirect: '/open-haus-thank-you',
    form: 'open-haus-subscribe',
  }
  const handleClick = () => {
    dispatch(setLightboxForm(isClosed ? subscribeForm : registerForm))
  }

  const openCopy = (
    <Copy>
      Register for{' '}
      <Link to="/open-haus-tarragindi">
        Open Haus: Annerley Haus 18-19 March
      </Link>
    </Copy>
  )

  const closedCopy = (
    <>
      <p>
        Please register your interest to receive information about Open Haus in
        the future.
      </p>
      <PageButtons
        align="left"
        data={[
          {
            label: isClosed ? 'Subscribe' : 'Pre-Register',
            onClick: handleClick,
            background: theme.colors.grey1,
          },
        ]}
      />
    </>
  )

  return (
    <>
      <SEO
        title="Open Haus"
        description="Experience our Display Haus, a home that engages with its
                surrounds and maximises the ability to enjoy the benefits of
                life in the subtropics."
        images={ogImages}
      />
      <PageHeader title={'Open Haus'} />
      <BlogContainer borderTop pb={['36px', '86px']}>
        <BlogQuoteRow
          data={{
            content: (
              <>
                <p>
                  Open Haus is an opportunity experience our homes and talk to
                  us about what makes a Happy Haus.
                </p>
              </>
            ),
          }}
        />
      </BlogContainer>
      <BlogContainer>
        <BlogMediaRowFull
          data={{
            mediaCaption: {
              mediaCaption: <>James Haus, New Farm.</>,
            },
            images: [
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-2024-02-15-photo-tarragindi-1110199.jpg',
                },
                fixed: {
                  width: 480,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-2024-02-15-photo-tarragindi-1110220.jpg',
                },
                fixed: {
                  width: 1078,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-2023-09-30-photo-tarragindi-1040081 copy.jpg',
                },
                fixed: {
                  width: 480,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-photo-dewar-AM-3.jpg',
                },
                fixed: {
                  width: 1078,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-photo-dewar-AM-2.jpg',
                },
                fixed: {
                  width: 480,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-2021-09-10-photo-annerley-JG-1040817.jpg',
                },
                fixed: {
                  width: 1078,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-2021-09-10-photo-annerley-JG-1040911.jpg',
                },
                fixed: {
                  width: 480,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-2021-09-10-photo-annerley-JG-1050130.jpg',
                },
                fixed: {
                  width: 1078,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-2021-09-10-photo-annerley-JG-1040847.jpg',
                },
                fixed: {
                  width: 480,
                  height: 720,
                },
              },
              {
                file: {
                  url: '/images/custom-haus/happyhaus-gallery-2022-03-23-photo-grant-3-JG-1020920.jpg',
                },
                fixed: {
                  width: 480,
                  height: 720,
                },
              },
            ],
          }}
        />
      </BlogContainer>
      <BlogContainer>
        <BlogCopyRow
          data={{
            images: [
              {
                fluid: {
                  src: '/images/custom-haus/happyhaus-gallery-2024-02-15-photo-tarragindi-1110214.jpg',
                },
              },
              {
                fluid: {
                  src: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110981.jpg',
                },
              },
              {
                fluid: {
                  src: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010441.jpg',
                },
              },
              {
                fluid: {
                  src: '/images/custom-haus/happyhaus-gallery-2022-09-20-photo-orari-JG-1080091.jpg',
                },
              },
              {
                fluid: {
                  src: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100713.jpg',
                },
              },
              {
                fluid: {
                  src: '/images/custom-haus/happyhaus-gallery-2024-05-23-photo-olive-1150588.jpg',
                },
              },
              {
                fluid: {
                  src: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010433.jpg',
                },
              },
              {
                fluid: {
                  src: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100590.jpg',
                },
              },
            ],

            copyHtml: (
              <div>
                <p>
                  Open Haus is an opportunity for you to see select homes
                  throughout the year. Experience the high level of finish and
                  construction, and talk to us about what makes a Happy Haus.
                  Register your interest to hear about select open homes
                  available for a walk through in the future.
                </p>

                <OutlineButton onClick={handleClick}>Register</OutlineButton>
              </div>
            ),
          }}
        />
      </BlogContainer>
      <BlogContainer>
        <BlogSubscribeRow
          data={{
            image: {
              file: { path: '/images/custom-haus/subscribe-graphic.png' },
            },
            copyHtml: 'Learn about the custom haus process',
            buttonText: 'Download',
            theme: 'Subtropical Homes',
            hubspotConversionType: 'Custom Haus Guide',
          }}
        />
      </BlogContainer>
      <BlogContainer attachTop>
        <BlogMediaRowFull
          data={{
            style: 'inset',
            media: {
              file: {
                path: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110986.jpg',
              },
            },
          }}
        />
      </BlogContainer>
      <BlogContainer attachTop pb={['46px', '86px']}>
        <BlogQuoteRow
          data={{
            content: (
              <>
                <p>
                  If you’re considering a Happy Haus or about to embark on a new
                  haus project, we can’t wait to say, <i>“Welcome home!”</i>
                </p>
                <h5>LACHLAN & RICK</h5>
              </>
            ),
          }}
        />
      </BlogContainer>
      <BlogRelated
        data={{
          relatedPosts: [
            {
              slug: 'top-sales-results-in-new-farm-and-tarragindi',
              title: 'Top sales results in New Farm and Tarragindi',
              category: ['Noteworthy'],
              thumbnail: {
                file: {
                  path: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100859.jpg',
                },
              },
            },
            {
              title: 'Making more neighbourhood memories',
              slug: 'silex-haus-a-legacy-of-memories',
              category: ['Owners Club'],
              thumbnail: {
                file: {
                  path: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010415.jpg',
                },
              },
            },
          ],
        }}
      />
    </>
  )
}

export default connect(mapDispatchToProps)(Page)

const ImageWrap = styled.div`
  margin-bottom: 1em;
  img {
    display: block;
  }
`
const HalfRight = styled(Box)`
  margin-bottom: 1em;
  width: 50%;
  img {
    display: block;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

const HalfLeft = styled(Box)`
  margin-bottom: 1em;
  width: 50%;
  float: right;
  img {
    display: block;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`
const CopyRight = styled(Box)`
  a {
    border-bottom: 1px solid #31333a;
  }
`

const PageHeading = styled(Box)`
  font-weight: normal;
  font-family: ${theme.font.fontFamilySerifThin};
  font-size: 30px;
  line-height: 140%;
  width: 50%;

  @media (max-width: 800px) {
    width: 90%;
  }
`
